import { useGLTF } from '@react-three/drei/'
import { AnamorphicModel } from '../../../../services/modelService';
import { useXrStore } from '../../../../services/xrService';
import { GroundPlane } from '../../../GroundPlane';

const AnamorphicAR = (props) => {

  const { modelPositionAR, modelRotationAR, scaleAnamorphicParam } = useXrStore();
  const glbText = useGLTF("/3d/Text.glb");
  const glbCube = useGLTF("/3d/Cube.glb");

  return (
    <>
      {!props.isVisible && <GroundPlane groundRef={props.groundRef} wallRef={props.wallRef} /> }
      <AnamorphicModel position={modelPositionAR} rotation={modelRotationAR} scale={scaleAnamorphicParam} glbCube={glbCube} glbText={glbText} povRef={props.povRef} />
    </>
  );
};

export default AnamorphicAR;
