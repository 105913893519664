import styled from 'styled-components';
import { Paragraph, theme, Title } from '../globalStyles';
import introBackgroundImage from '../assets/backgroundSAP.jpg';
import introBackgroundImageIpad from '../assets/backgroundSAP_ipad.png';
import endBackgroundImage from '../assets/endScreenWave.png';
import { Link } from 'react-router-dom';
import { vp } from '../utils/breakpoints';

export const IntroContainer = styled.div`
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  align-items: center;
`;

export const ViewContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: no-repeat top center url(${introBackgroundImage});
  display: flex;
  padding-top: 20vh;

  ${vp.medium(`
    background: no-repeat top center url(${introBackgroundImageIpad}); 
    background-size: cover;
  `)}
`;

export const TitleTxtContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 2rem 0 1.5rem;
  text-align: left;
`;

export const EndViewTitleTxtContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 17vh 3rem;
  text-align: left;
`;

export const TitleTxt = styled(Title)`
  margin: 0;

  ${vp.medium(`
    font-size: 62px;
    line-height: 68px;
    margin-bottom: 20px;
  `)}
`;

export const ParagraphTxt = styled(Paragraph)`
  ${vp.medium(`
    font-size: 21px;
    line-height: 30px;
  `)}
`;

export const ButtonContainer = styled.div`
  position: fixed;
  bottom: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 50%;
  height: 40px;
  display: flex;
  align-items: left;
  justify-content: space-around;
  z-index: 10;
  margin: 0 0 1rem 1rem;
`;

export const FooterLink = styled.a`
  color: ${theme.colors.white};
  margin-top: 15px;
  font-size: 0.75rem;
  padding: 0.5rem 0;
  transition: all 0.3s linear;
  &:hover {
    border-bottom: 1px solid white;
  }
`;

export const EndViewContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(37, 53, 93, 0.3) url(${endBackgroundImage}) fixed center
    center;
  display: flex;
  /* padding-top: 20vh; */
`;
