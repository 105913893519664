import React from 'react'
import { EndViewContainer, ButtonContainer, EndViewTitleTxtContainer, TitleTxt} from './styles'
import ButtonArrow from '../components/buttons/ButtonArrow'
import SAPLogo from '../components/Logo';

const EndView = () => {
  return (
    <EndViewContainer>
        <SAPLogo/>
        <EndViewTitleTxtContainer>
        <TitleTxt>Continue transforming your processes with S/4HANA.</ TitleTxt>
        </EndViewTitleTxtContainer>
        <ButtonContainer>
            <ButtonArrow
                //@ts-ignore
                style={{ marginBottom: "60px" }}
                width="280"
                isWhite
                text="Continue the Experience"
                onClick={() => (window.open('https://www.sap.com/dmc/exp/2022-01-decoding-success/', '_blank'))}
            />
        </ButtonContainer>
        <ButtonContainer>
            <ButtonArrow
                width="280"
                isWhite
                text="Request a Demo"
                onClick={() => (window.open('https://www.sap.com/products/s4hana-erp.html', '_blank'))}
            />
        </ButtonContainer>
    </EndViewContainer>
  )
}

export default EndView
