import styled from 'styled-components';
import Lottie from 'lottie-react';
import  { BoldText }  from '../../globalStyles';

export const OverlayContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #0c1524f2; // 95% opacity
  z-index: 12000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const Headline = styled(BoldText)`
  max-width: 240px;
  font-size: 16px;
  margin-top: 1rem;
`;

export const LottieContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledLottie = styled(Lottie)`
  height: 90px;
`;
