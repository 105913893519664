import styled from 'styled-components'
import { vp } from '../../utils/breakpoints'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const LottieContainer = styled.div`
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${vp.medium(`
    width: 35%;
  `)}
`;

export const Headline = styled.h3`
  text-align: center;
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  margin: 0;
`;

export const Paragraph = styled.p`
  text-align: center;
  font-size: 13px;
  font-weight: 900;
  line-height: 25px;
  margin: 0;
`;

