import ButtonArrow from '../components/buttons/ButtonArrow';
import { FooterContainer, FooterLink, TitleTxt, TitleTxtContainer, ViewContainer, ButtonContainer, } from './styles'
import SAPLogo from '../components/Logo';
import { useHistory } from 'react-router-dom';
import { Paragraph } from '../globalStyles'
import { ContentModel } from '../../src/utils/data';

function PermissionPage() {
  const history = useHistory();
  return (
    <>
      <SAPLogo />
      <ViewContainer>
        <TitleTxtContainer>
          <TitleTxt>{ContentModel.permissionsPage.headline}</TitleTxt>
          <Paragraph>{ContentModel.permissionsPage.intro_txt}</Paragraph>
        </TitleTxtContainer>
        <ButtonContainer>
          <ButtonArrow
            width="280"
            isWhite
            text={ContentModel.permissionsPage.instructions}
            onClick={() => history.push('/graph_ar')}
          />
        </ButtonContainer>
        <FooterContainer>
          <FooterLink href="https://www.sap.com/corporate/en/legal/impressum.html" target="_blank">Imprint</FooterLink>
          <FooterLink href="https://www.sap.com/about/legal/privacy.html" target="_blank">Data Protection</FooterLink>
        </FooterContainer>
      </ViewContainer>
    </>
  );
}

export default PermissionPage;
