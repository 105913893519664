/* eslint-disable max-lines */
import { theme } from '../../../../globalStyles';
import styled from 'styled-components';
import introBackgroundImage from '../../../../assets/backgroundSAP.jpg';
import introBackgroundImageIpad from '../../../../assets/backgroundSAP_ipad.png';
import { vp } from '../../../../utils/breakpoints';

export const LoadingContainer = styled.div`
  background: no-repeat top center url(${introBackgroundImage});
  z-index: 800;

  ${vp.medium(`
    background: no-repeat top center url(${introBackgroundImageIpad}); 
    background-size: cover;
  `)}


  #loadBackground {
    background: no-repeat top center url(${introBackgroundImage});

    ${vp.medium(`
      background: no-repeat top center url(${introBackgroundImageIpad}); 
      background-size: cover;
    `)}
  }

  .xrextras-old-style #loadBackground {
    background: no-repeat top center url(${introBackgroundImage});

    ${vp.medium(`
      background: no-repeat top center url(${introBackgroundImageIpad}); 
      background-size: cover;
    `)}
  }

  #requestingCameraPermissions {
    z-index: 11;
    position: absolute;
    top: 0;
    width: 100vw;
    text-align: center;
    color: white;
    font-size: 1.8em;
    font-family: Open-Sans, Helvetica, Sans-Serif;
    background: no-repeat top center url(${introBackgroundImage});
    padding-top: 3vh;
    padding-bottom: 1.75vh;
    display: none;

    ${vp.medium(`
      background: no-repeat top center url(${introBackgroundImageIpad}); 
      background-size: cover;
    `)}


  #requestingCameraIcon {
    display: block;
    margin: 0 auto;
    margin-bottom: 2vh;
    height: 5.5vh;
  }

  #loadImage {
    position: absolute;
    margin-top: -2.5em;
    margin-left: -2.5em;
    top: 50%;
    left: 50%;
    height: 5em;
    width: 5em;
    transform: translate(-50%, -50%);
  }

  /* camera and micrphone permission related styles */
  #cameraPermissionsErrorApple,
  #microphonePermissionsErrorApple {
    background: no-repeat top center url(${introBackgroundImage}) !important;

    ${vp.medium(`
      background: no-repeat top center url(${introBackgroundImageIpad}) !important; 
      background-size: cover;
    `)}
  }

  .xrextras-old-style #cameraPermissionsErrorApple,
  .xrextras-old-style #microphonePermissionsErrorApple {
    background: no-repeat top center url(${introBackgroundImage});

    ${vp.medium(`
      background: no-repeat top center url(${introBackgroundImageIpad}); 
      background-size: cover;
    `)}
  }

  #cameraPermissionsErrorAppleMessage,
  #microphonePermissionsErrorAppleMessage {
    font-size: 1.75em;
    text-align: left;
    max-width: 90vw;
    margin-top: 5vh;
    margin-left: auto;
    margin-right: auto;
    color: white;
  }

  .xrextras-old-style #cameraPermissionsErrorAppleMessage,
  .xrextras-old-style #microphonePermissionsErrorAppleMessage {
    color: #323232;
  }

  .permissionIconIos {
    display: block;
    margin: 0 auto;
    margin-top: 17vh;
    text-align: center;
  }
  .permissionIconIos img {
    height: 20vw;
  }
  .permissionIconIos img + img {
    margin-left: 10vw;
  }

  .bottom-message {
    color: white;
    padding: 0 5vw;
    position: absolute;
    bottom: 10vh;
    text-align: center;
    font-size: 1.25em;
  }

  #cameraPermissionsErrorAndroid,
  #microphonePermissionsErrorAndroid {
    padding: 2vh 0;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    justify-content: space-around;
    align-items: center;
    background: no-repeat top center url(${introBackgroundImage});
    ${vp.medium(`
    background: no-repeat top center url(${introBackgroundImageIpad}); 
    background-size: cover;
  `)}
  }

  .xrextras-old-style #cameraPermissionsErrorAndroid,
  .xrextras-old-style #microphonePermissionsErrorAndroid {
    background-color: ${theme.colors.white};
  }

  /* device motion permission related styles */

  #deviceMotionErrorApple {
    padding: 3vh 2vh;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    justify-content: space-around;
    align-items: center;
    background-color: ${theme.colors.white};
    color: white;
  }

  .xrextras-old-style #deviceMotionErrorApple {
    background: no-repeat top center url(${introBackgroundImage});
    ${vp.medium(`
    background: no-repeat top center url(${introBackgroundImageIpad}); 
    background-size: cover;
  `)}
    color: ${theme.colors.red};
  }

  #linkOutViewAndroid,
  #copyLinkViewAndroid {
    background: no-repeat top center url(${introBackgroundImage});
    ${vp.medium(`
    background: no-repeat top center url(${introBackgroundImageIpad}); 
    background-size: cover;
  `)}
  }

  .xrextras-old-style #linkOutViewAndroid,
  .xrextras-old-style #copyLinkViewAndroid {
    background-color: ${theme.colors.white};
  }

  .permission-error {
    padding: 3vh 5vh;
    font-size: 3.5vh;
    background: no-repeat top center url(${introBackgroundImage});
    ${vp.medium(`
    background: no-repeat top center url(${introBackgroundImageIpad}); 
    background-size: cover;
  `)}
  }

  .xrextras-old-style .permission-error {
    color: #323232;
    background-color: ${theme.colors.white};
  }

  .permission-error > h1 {
    font-size: 1.3em;
  }

  .main-button {
    border: none;
    outline: none;
    background-color: ${theme.colors.red};
    color: white;
    font-size: 2.5vh;
    display: block;
    margin-top: 2em;
    padding: 0.5em 1em;
    border-radius: 0.5em;
  }

  .xrextras-old-style .main-button {
    background-color: ${theme.colors.red};
  }

  .start-ar-button {
    position: fixed;
    bottom: 25%;
    left: 50%;
    transform: translateX(-50%);
    font-family: 'Nunito-SemiBold', sans-serif;
    font-weight: 800;
    font-size: 1.5em;
    text-align: center;
    color: white;
    text-decoration: none;
    border: none;
    background-color: ${theme.colors.red};
    padding: 6px 13px;
    border-radius: 10px;
  }

  .xrextras-old-style .start-ar-button {
    background-color: ${theme.colors.red};
  }

  .permissionIcon {
    overflow: hidden;
    flex: 0 0 auto;
    margin-bottom: 2vh;
  }

  .permissionIcon img {
    display: block;
    margin: 0 auto;
    height: 5vh;
  }

  #cameraSelectionWorldTrackingError {
    z-index: 999;
    position: absolute;
    top: 0;
    width: 100vw;
    text-align: center;
    color: black;
    font-size: 3.7vh;
    background-color: white;
    padding: 3vh 0;
  }

  .loading-error-header {
    font-size: 3.5vh;
    flex: 0 0 auto;
    color: ${theme.colors.red};
  }

  .xrextras-old-style .loading-error-header {
    color: #323232;
  }

  .loading-error-footer {
    font-size: 3vh;
    line-height: 5.5vh;
    flex: 0 0 auto;
    text-align: center;
    width: 80vmin;
    color: white;
  }

  .xrextras-old-style .loading-error-footer {
    color: #323232;
  }

  .loading-error-footer img {
    display: block;
    height: 5vh;
    margin: 0 auto;
    margin-bottom: 2vh;
  }

  .loading-error-instructions {
    font-family: Open-Sans, Helvetica, Sans-Serif;
    color: #fff;
    font-size: 2.5vh;
    list-style: none;
    margin-left: 1em;
    counter-reset: line;
    flex: 0 0 auto;
  }

  .xrextras-old-style .loading-error-instructions {
    color: #2d2e43;
  }

  .loading-error-instructions > li {
    position: relative;
    margin-bottom: 4.5vh;
  }

  .loading-error-instructions > li > img {
    max-height: 3vh;
    vertical-align: middle;
    margin: 0 0.5vh;
  }

  .loading-error-instructions > li:before {
    font-family: Open-Sans, Helvetica, Sans-Serif;
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 3vh;
    color: ${theme.colors.red};
    background-color: ${theme.colors.white};
    text-align: center;
    left: -8vh;
    top: -1vh;
    font-size: 2.5vh;
    line-height: 5.5vh;
    counter-increment: line;
    content: counter(line);
  }

  .xrextras-old-style .loading-error-instructions > li:before {
    background-color: ${theme.colors.white};
  }

  .highlight {
    color: ${theme.colors.white};
    font-weight: 800;
  }

  .xrextras-old-style .highlight {
    color: ${theme.colors.white};
  }

  .camera-instruction-block {
    display: inline-block;
    background: no-repeat top center url(${introBackgroundImage});
    padding: 1vh;
    ${vp.medium(`
    background: no-repeat top center url(${introBackgroundImageIpad}); 
    background-size: cover;
  `)}
  }

  .xrextras-old-style .camera-instruction-block {
    background: no-repeat top center url(${introBackgroundImage});
    ${vp.medium(`
    background: no-repeat top center url(${introBackgroundImageIpad}); 
    background-size: cover;
  `)}
  }

  .camera-instruction-button {
    display: inline-block;
    padding: 1vh;
    background-color: ${theme.colors.red};
    color: white;
    font-size: 2vh;
    box-shadow: 0 0.125vh 0.25vh rgba(0, 0, 0, 0.5);
  }

  .fade-out {
    animation: fade-out 0.3s linear forwards;
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .spin {
    animation: spin 1.1s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite both;
  }

  @keyframes spin {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .scale {
    -webkit-animation: scale 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite
      alternate-reverse both;
    animation: scale 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite
      alternate-reverse both;
  }

  @keyframes scale {
    0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  .pulse {
    -webkit-animation: pulse 1s ease-in-out infinite both;
    animation: pulse 1s ease-in-out infinite both;
  }

  @keyframes pulse {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  .fade-out {
    opacity: 0;
  }

  .poweredby-img {
    left: 50%;
    transform: translateX(-50%);
    bottom: 50px;
    display: block;
    position: absolute;
    width: 150px;
  }
  }`;
