import styled from 'styled-components'
import { Link } from "react-router-dom";

export const UIGraphContainer = styled.div`
  z-index: 10;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  box-shadow: inset 0px 130px 75px 0 rgba(50,50,50, 0.6);
  overflow: hidden;
`;

export const LinkExitExperience = styled(Link)`
  bottom: 15px;
  color: ${({theme}) => theme.colors.white};
  text-transform: uppercase;
  text-decoration: underline !important;
  position: fixed;
  left: 50%;
  font-family: "72 Bold";
  transform: translate(-50%);
  z-index: 9999;
`