import { PerspectiveCamera, Scene, WebGLRenderer, PCFSoftShadowMap, Vector3, Matrix4, Quaternion } from 'three';
// import { PerspectiveCamera, Scene, WebGLRenderer, PCFSoftShadowMap, GLTFLoader, WebGLRenderTarget,ReinhardToneMapping,LinearFilter,RGBAFormat,Vector2, Color, Clock } from 'three';

import { useXrStore } from '../../../services/xrService';
import { unstable_batchedUpdates } from 'react-dom'; // or 'react-native'
import { useGameStore } from '../../../services/gameService';

/*
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'

import {TexturePass} from 'three/examples/jsm/postprocessing/TexturePass';

import {ShaderPass} from 'three/examples/jsm/postprocessing/ShaderPass';
import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass';

// eslint-disable-next-line import/no-webpack-loader-syntax
import bloomVertexShader from '!!raw-loader!../../../shaders/bloomEffectVertex.glsl';
// eslint-disable-next-line import/no-webpack-loader-syntax
import bloomFragmentShader from '!!raw-loader!../../../shaders/bloomEffectFragment.glsl';
*/

const customThreejsPipelineModule = () => {
  let alt = 0;
  let auxInterval = false;
  let engaged = false;
  let scene3;
  // let clock = null;

  const engage = ({ canvas, canvasWidth, canvasHeight, GLctx }) => {
    // INITIALISE
    if (engaged) {
      return;
    }

    const scene = new Scene();

    const camera = new PerspectiveCamera(
      //initial field of view; will get set based on device info later.
      60.0,
      canvasWidth / canvasHeight,
      0.01,
      1000.0
    );
    camera.position.set(0, 5, 0);
    scene.add(camera);

    // clock = new Clock();
    let renderer = useXrStore.getState().renderer;

    renderer.autoClear = false;
    renderer.setSize(canvasWidth, canvasHeight);

    scene3 = { scene, camera };


    unstable_batchedUpdates(() => {
      // @ts-ignore
      useXrStore.getState().setScene3(scene3);
    });

    engaged = true;
  };

  // Places content over image target
  const showTargetHandler = param => {
    unstable_batchedUpdates(() => {
      const scene3 = useXrStore.getState().scene3;
      const game = useGameStore.getState().currentGame;

      // First check if we're in game 1 (anamorphic AR) or undefined url
      if (game === 'anamorphic_ar') {
        const { detail } = param;
        const foundModel = scene3.scene.scene.children[1].children.find(child => child.name === 'anamorphicARGroup');
        // When the image target named 'model-target' is detected, show 3D model.
        // This string must match the name of the image target uploaded to 8th Wall.
        // !imgTargetDet to only run first time => var now not existent 
        if (detail && detail.name === 'target-8') {
          // scene to manipulate => scene3.scene.scene  
          scene3.scene.scene.position.set(detail.position.x, detail.position.y, detail.position.z); // group of objects where model lies

          // Find index of model in scene. Then access its properties:
          // Adjust position model on target, looking towards user
          foundModel.scale.set(6, 6, 6);
          foundModel.position.copy(scene3.scene.scene.position)
          foundModel.translateZ(-3);
          foundModel.lookAt(scene3.scene.camera.position.x, scene3.scene.camera.position.y + 3, scene3.scene.camera.position.z); //.y+3: easier to view

          useXrStore.getState().setFirstCameraPos(scene3.scene.camera.position);
          useXrStore.getState().setIsLookingAtCamera(true);

        }
      }
    });
  };

  // Hides the image frame when the target is no longer detected.
  const hideTargetHandler = param => {
    unstable_batchedUpdates(() => {
      /* const { detail } = param;
      if (detail && detail.name === 'target-8') {
        // since we want to continue tracking if we lose the target we dont do anything
      } */
    });
  };

  const DirectionVector = (v1, v2) => {
    return new Vector3(
      v2.x - v1.x,
      v2.y - v1.y,
      v2.z - v1.z
    );
  };

  const rad2deg = (radians) => {
    var pi = Math.PI;
    return radians * (180 / pi);
  };

  return {
    name: 'customthreejs',
    onStart: args => engage(args),
    onAttach: args => engage(args),

    onDetach: () => {
      engaged = false;
    },

    onUpdate: ({ processCpuResult }) => {
      const realitySource = processCpuResult.reality || processCpuResult.facecontroller;
      if (!realitySource) {
        return;
      }

      const { rotation, position, intrinsics } = realitySource;
      const { camera } = scene3;

      //generateAngles();

      for (let i = 0; i < 16; i++) {
        camera.projectionMatrix.elements[i] = intrinsics[i];
      }

      // Fix for broken raycasting in r103 and higher. Related to:
      //   https://github.com/mrdoob/three.js/pull/15996
      // Note: camera.projectionMatrixInverse wasn't introduced until r96 so check before setting
      // the inverse
      if (camera.projectionMatrixInverse) {
        if (camera.projectionMatrixInverse.invert) {
          // THREE 123 preferred version
          camera.projectionMatrixInverse.copy(camera.projectionMatrix).invert();
        } else {
          // Backwards compatible version
          camera.projectionMatrixInverse.getInverse(camera.projectionMatrix);
        }
      }

      if (rotation) {
        camera.setRotationFromQuaternion(rotation);
      }
      if (position) {
        camera.position.set(position.x, position.y, position.z);
      }
    },
    onCanvasSizeChange: ({ canvasWidth, canvasHeight }) => {
      if (!engaged) {
        return;
      }
      let renderer = useXrStore.getState().renderer;
      renderer.setSize(canvasWidth, canvasHeight);
    },

    onRender: () => {
      // const { scene, camera, composer } = scene3;
      const { scene, camera } = scene3;
      // let delta = clock.getDelta();
      let renderer = useXrStore.getState().renderer;

      if (renderer) {
        renderer.clearDepth();
        renderer.render(scene, camera);
      }

      // composer.render(delta);
    },

    xrScene: () => {
      return scene3;
    },

    // Listeners are called right after the processing stage that fired them. This guarantees that
    // updates can be applied at an appropriate synchronized point in the rendering cycle.
    // listeners: [
    //   { event: 'reality.imagefound', process: showTargetHandler },
    //   { event: 'reality.imageupdated', process: showTargetHandler },
    //   { event: 'reality.imagelost', process: hideTargetHandler },
    // ],
  };
};
export default customThreejsPipelineModule;
