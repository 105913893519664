export default new Float32Array([
//Studio
 1.361521244049072,  1.592738509178162,  1.941882729530334, 
 0.834527790546417,  0.973758518695831,  1.182983994483948, 
 0.531079351902008,  0.621887564659119,  0.759838819503784,
-1.038407444953918, -1.214803934097290, -1.483230590820313,
-0.143312796950340, -0.167427211999893, -0.203745722770691,
 0.072688169777393,  0.084983594715595,  0.103501550853252,
-0.017314992845058, -0.020217088982463, -0.024551915004849,
-0.087908431887627, -0.102982610464096, -0.126021698117256,
 0.012701759114861,  0.015006247907877,  0.018845051527023,
],
);