import React, { useRef, useEffect } from 'react';
import { useModelStore } from '../../../services/modelService';
import { useGraphStore } from '../../../services/graphService';
import { useXrStore } from '../../../services/xrService';
import { useLoader } from '@react-three/fiber';
import { TextureLoader, DoubleSide } from 'three';
import markerImg from "./../../../img/Top-view-marker.png"
import { PLANE_HEIGHT } from '../../../constants';

const GraphModel = (props) => {
  const { spheres, connections, loadStepInToGraph } = useGraphStore();
  const { sphereModel, connectionModel } = useModelStore();
  const { modelPositionAR, modelRotationAR, screenTouchedFirstTimeAR, scaleGraphParam } = useXrStore();

  const sphereRefs = useRef([]);
  const hotspotRefs = useRef([]);
  const connectionRefs = useRef([]);

  const markerTex = useLoader(TextureLoader, markerImg)

  useEffect(() => {
    loadStepInToGraph();
  }, []);

  const generateSpheres = (spheresData) => {
    return spheresData.map((sphereProps, i) => {
      return sphereModel.component(
        (el) => {
          sphereRefs.current[i] = el;
        }, // sphere ref
        (el) => {
          hotspotRefs.current[i] = el;
        }, // hotspot ref
        [sphereProps.x, sphereProps.y, sphereProps.z], // position
        sphereProps.pointid, // id
        sphereProps.status, // status
        props.isVisible, //for visibility of hotspots. placed=visible
        sphereProps.connectionids,
      );
    });
  };

  const generateConnections = (connectionData) => {
    return connectionData.map((connection, i) => {

      var endSphere = spheres.find(sphere => sphere.connectionids.indexOf(connection.id) > -1);
      var startSphere = spheres.find(sphere => endSphere.pointid !== sphere.pointid && sphere.connectionids.indexOf(connection.id) > -1);
      if(startSphere == null) {
        startSphere = endSphere;
        console.error("Could not identify partner sphere for connection " + connection.id + "! This may cause line ends to be displayed inncorrectly!");
      }

      return connectionModel.component(
        (el) => {
          connectionRefs.current[i] = el;
        },
        [0, 0, 0],
        connection.points,
        connection.id,
        connection.thickness,
        connection.lineamount,
        startSphere.pointid,
        endSphere.pointid,
      );
    });
  };

  const generateGraphModel = (spheres, connections) => {
    let sphereArray = generateSpheres(spheres);
    let connectionArray = generateConnections(connections);
    return[...sphereArray,...connectionArray];
  }

  return (
    <group
      name={"graphAndMarkerContent"}
      dispose={null}
      scale={scaleGraphParam}
      position={modelPositionAR}
      rotation={modelRotationAR}
    >
      <mesh visible={!props.isVisible} position={[0, -PLANE_HEIGHT/2, 0]} rotation={[-Math.PI * 0.5, 0.0, 0.0]}>
        <planeBufferGeometry args={[1,3]}/>
        <meshBasicMaterial side={DoubleSide} attach="material" transparent={true} map={markerTex} />
      </mesh>
      <group name={'graphContent'} visible={props.isVisible} >
        {generateGraphModel(spheres, connections)}
      </group>
    </group>
  );
};

export default GraphModel;
