import PopUpContainer from '../PopUpContainer';
import { Container, Headline, LottieContainer, Paragraph } from './styles';
import Lottie from 'lottie-react';
import mobileAnimation from '../../lottie/Mobile_search.json';
import mobileAnimation2 from '../../lottie/Mobile_search_2.json';

type InitialPopup = {
  game: string,
  showInstructions?: boolean,
  isThereHealthy?: boolean
}

const InitialPopUp: React.FC<InitialPopup>  = ({ game, isThereHealthy, showInstructions }) => {
  const isAnamorphic = game === 'anamorphic_ar'
  const helpLineText = isAnamorphic ? "Move around to change perspective" : "Check Your Activities";
  const helpTextGuidance = isThereHealthy ?  'Activity Improved' : 'Excellent! No issues found.';

  return (
    <PopUpContainer>
      <Container>
        <LottieContainer>
          <Lottie
            autoplay
            loop={true}
            animationData={isAnamorphic ? mobileAnimation2 : mobileAnimation}
          />
        </LottieContainer>
      </Container>
      <Headline>
        { showInstructions ? helpTextGuidance : helpLineText }
      </Headline>
      {showInstructions &&
        <Paragraph>
          Move to Next Activity
        </Paragraph>
      }
    </PopUpContainer>
  );
};

export default InitialPopUp;
