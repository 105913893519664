import styled from 'styled-components'
import { BlackText, Paragraph } from '../../globalStyles'
import { vp } from '../../utils/breakpoints'



export const CloseBtnContainer = styled.div`
  font-size: 18px;
  align-self: end;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 8%;
  height: 8%;
  ${vp.medium(`
  width: 5%;
  height: 5%;
  `)}
`

export const ElementsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const HeadLineText = styled(BlackText)`
  font-size: 17px;
  margin: 0;
`

export const DescriptionText = styled(Paragraph)`
  font-size: 15px;
  margin: 12px 0;
  line-height: 22px;
  text-align: center;
`
