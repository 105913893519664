import React from 'react';
import styled from 'styled-components';

const ZoomButton = (props) => {
    return(
        <ZoomBtnContainer>
            <ZoomBtn primary onClick={props.zoomIn}>+</ZoomBtn>
            <ZoomBtn onClick={props.zoomOut}>-</ZoomBtn>
        </ZoomBtnContainer>
    )
}

const ZoomBtn = styled.button`
    align-items: center;
    border: none;
    border-radius: ${props => props.primary ? "12px 12px 0 0" : "0 0 12px 12px"};
    color: white;
    background-color: #25355D;
    font-size: 36px;
    font-weight: 1000;
    font-family: monospace, monospace;
    width: 60px;
    height: 70px;
    padding:0;
    margin-bottom: ${props => props.primary ? "-1px": "0"};
`;
const ZoomBtnContainer = styled.div`
    display: flex;
    position: absolute; 
    flex-direction: column;
    left: 90%;
    transform: translate(-50%, -50%);
    top: 50%;
`

export default ZoomButton;