import styled from 'styled-components'
import { BoldText } from '../../globalStyles'
import { vp } from '../../utils/breakpoints'

export const FocusAreaContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
	pointer-events: none;
`;

export const FocusAreaIcon = styled.div`
  position: relative;
	width: 60vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* for adjusting the focus lens to screen position (0,0) */
  padding-bottom: 80px;
`;

export const FocusAreaText = styled(BoldText)`
text-transform: uppercase;
position: absolute;
width: fit-content;
top: 13vh;
font-size: 13px;
${vp.medium(`
  top: 10vh;
  `)}
`

export const LottieContainer = styled.div`
  width: 57%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${vp.medium(`
    width: 27%;
  `)}
`
