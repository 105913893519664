import styled from 'styled-components';
import { BoldText, BlackText } from '../../globalStyles';
import { vp } from '../../utils/breakpoints';

const redGradient = 'linear-gradient(270.01deg, #F12929 2.14%, #FF5045 74.12%)';
const blueGradient  = 'linear-gradient(270.01deg, #378AD7 2.14%, #2339FF 74.12%)';
const greenGradient = 'linear-gradient(270.01deg, #93FAB1 2.14%, #45FFD2 74.12%)';

const handleColorType = width => width > 42 ? redGradient : blueGradient

const handleAnimationBar = (width, isRunningAnimation) =>
  isRunningAnimation ? greenGradient: handleColorType(width);


export const TimeIndicatorContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin: 0.6rem 0.6rem 0 0;
  min-width: 35vw;
  letter-spacing: 0.52px;

  > * {
    margin: 0;
    padding: 0;
  }

  ${vp.medium(`
    margin: 0.8rem 0.8rem 0 0;
    width: 70px;
  `)}
`;

export const HeadLine = styled(BoldText)`
  font-size: 0.85rem;
  text-align: right;

  ${vp.medium(`
    font-size: 1rem;
  `)}
`;
export const TimeContainer = styled.div`
  height: fit-content;
  width: 125px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > * {
    margin: 0.3rem 0 0 0;
    padding: 0;
  }
`;

export const ClockIconContainer = styled.div`
  width: 20px;
  height: 20px;
  padding-right: 0.3rem;
  height: fit-content;
`;

export const DaysCount = styled(BlackText)`
  padding-right: 0.5rem;
  ${vp.medium(`
    margin-bottom: 10px;
  `)}
`;

export const HoursCount = styled(BlackText)`
  ${vp.medium(`
    margin-bottom: 10px;
  `)}
`;

export const ProgressBarContainer = styled.div`
  min-width: 125px;
  width: 100%;
`;

export const ProgressBarBack = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 28px;
  width: 100%;
  height: 8px;
  position: relative;
`;

export const ProgressBar = styled.div`
  background: ${({ width, isRunningAnimation }) => handleAnimationBar(width, isRunningAnimation)};
  border-radius: 28px;
  width: ${(props) => `${props.width}%`};
  transition: width 1s ease;
  height: 8px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
`;
