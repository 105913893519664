import React, { useEffect, useMemo } from 'react';
import { TouchArea } from './styles';
import { useXrStore } from '../../../services/xrService';
import { useGraphStore } from '../../../services/graphService';
import { Raycaster, Vector2 } from 'three';
import { handle3DEvents } from '../../../utils/events/events';
import { GRAPH_SCREENS, useUIStore } from '../../../services/uiService';

export default function TouchContainer({ children }) {

  const { scene3, isSceneInitialised, screenTouchedFirstTimeAR, groundRef, wallRef } = useXrStore();
  const { setScreenGraphGame, showImprovementCircularBar, transitionScreenShowedUp } = useUIStore();
  const { getSelectedSphere } = useGraphStore()

  let scene = null;
  let requestAnim; // Where requestFrameAnimation will be stored

  if (isSceneInitialised) {
    scene = scene3.scene.scene;
  }

  const { raycaster } = useMemo(() => {
    if (!scene) return {};

    return {
      raycaster: new Raycaster(),
    };
  }, [scene, 
  ]);


  useEffect(() => {
    if (screenTouchedFirstTimeAR) {
      // When user places graph (screenTouched === true), stop graph placement logic && set new game
      transitionScreenShowedUp ? setScreenGraphGame(GRAPH_SCREENS.search) : setScreenGraphGame(GRAPH_SCREENS.transition);
      cancelAnimationFrame(requestAnim);
    }
  }, [screenTouchedFirstTimeAR]);

  // Starts raycaster process and handles intersections (from VIEWPORT_CENTER)
  const triggerRaycaster = (intersectedObj, intersectedObj2?) => {
    const VIEWPORT_CENTER: Vector2 = new Vector2(0, 0);
    raycaster.setFromCamera(VIEWPORT_CENTER, scene3.scene.camera);
    let intersects = raycaster.intersectObject(intersectedObj, true);
    intersects.length === 0 && intersectedObj2 && //if ground not detected, redefine to detect walls
      (intersects = raycaster.intersectObject(intersectedObj2, true)); 
    handle3DEvents(intersects);
  };

  // Every x time, raycaster "searches" for a sphere inside the focus area.
  useEffect(() => {
    const interval = setInterval(() => {
      // Raycast actions only if user has tapped and UI not being shown at that moment.
      if (screenTouchedFirstTimeAR && !getSelectedSphere() && !showImprovementCircularBar) {
        // Spheres in intersection are detected and validated
        triggerRaycaster(scene3.scene.scene);
      }
    }, 700);

    return () => clearInterval(interval);
  }, [screenTouchedFirstTimeAR, getSelectedSphere, raycaster, scene3]);

  // for model positioning 
  const updateAnimFrame = () => {
    if (!groundRef.current || !wallRef.current || screenTouchedFirstTimeAR) return;
    triggerRaycaster(groundRef.current, wallRef.current );
    requestAnim(updateAnimFrame);
  };

  const startAnimationFrameModelPreview = () => {
    if (isSceneInitialised) {
      requestAnim = requestAnimationFrame;
      requestAnim(updateAnimFrame);
    }
  };

  useEffect(() => {
    !screenTouchedFirstTimeAR && startAnimationFrameModelPreview();
  }, [scene, screenTouchedFirstTimeAR ]);

  return (
    <TouchArea>
      {children}
    </TouchArea>
  );
}
