import {
  ProgressBarContainer,
  TimeIndicatorContainer,
  TimeContainer,
  HoursCount,
  HeadLine,
  DaysCount,
  ClockIconContainer,
  ProgressBarBack,
  ProgressBar,
} from './styles';

import { ClockIcon } from '../../utils/svg';
import { useEffect, useState } from 'react';

type TimeIndicator = {
  days: number;
  hours: number;
  percentage: number;
}

const TimeIndicator:React.FC<TimeIndicator> = ({
    days,
    hours,
    percentage,
  }) => {

  // const [widthTest, setWidth] = useState(100)
  const [runAnimation, setRunAnimation] = useState(false);

  useEffect(() => {
    setRunAnimation(true);
    setTimeout(() => setRunAnimation(false) ,1000);
  },[percentage])

  return (
    <TimeIndicatorContainer>
      <HeadLine>Cycle Time</HeadLine>
      <TimeContainer>
        <ClockIconContainer>
          <ClockIcon />
        </ClockIconContainer>
        <DaysCount>{days + 'd'}</DaysCount>
        <HoursCount>{hours + 'h'}</HoursCount>
      </TimeContainer>
      <ProgressBarContainer>
        <ProgressBarBack>
          <ProgressBar width={percentage} isRunningAnimation={runAnimation}/>
        </ProgressBarBack>
      </ProgressBarContainer>
    </TimeIndicatorContainer>
  );
};

export default TimeIndicator;
