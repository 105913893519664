import { createGlobalStyle } from 'styled-components';
import styled, { css } from 'styled-components';
import introBackgroundImage from './assets/backgroundSAP.jpg';
import introBackgroundImageIpad from './assets/backgroundSAP_ipad.png';
import { vp } from './utils/breakpoints';

const lightFamily = ['72 Light', 'sans-serif'];
const primaryFamily = ['72 Regular', 'sans-serif'];
const boldFamily = ['72 Bold', 'sans-serif'];
const blackFamily = ['72 Black', 'sans-serif'];

const fontStyle = (family) => css`
  font-family: ${family.map(
    (ff, i) => `'${ff}'${i < family.length - 1 ? ',' : ''}`,
  )};
`;
export const lightFontStyle = () => css`
  ${fontStyle(lightFamily)}
`;
export const primaryFontStyle = () => css`
  ${fontStyle(primaryFamily)}
`;
export const boldFontStyle = () => css`
  ${fontStyle(boldFamily)}
`;
export const blackFontStyle = () => css`
  ${fontStyle(blackFamily)}
`;

export const theme = {
  colors: {
    red: '#ff0000',
    white: '#FFFFFF',
    grey: '#708090',
    blue: '#0f173f',
    lightBlue: '#0D90D9',
    black: '#000000',

    PopUpGraphContainer: {
      background: 'rgba(37,53,93,0.7)',
    },

    button: {
      primary: {
        color: '#25355D',
        colorHover: '#FFFFFF',
        background: '#FFFFFF',
        backgroundHover: '#233B98',
      },
      secondary: {
        color: '#FFFFFF',
        colorHover: '#233B98',
        background: '#25355D',
        backgroundHover: '#FFFFFF',
      },
    },
  },
};

export const Title = styled.h1`
  ${blackFontStyle()};
  font-size: 34px;
  line-height: 38px;
`;

export const Paragraph = styled.p`
  ${primaryFontStyle()};
  font-size: 15px;
  line-height: 28px;
`;

export const BoldText = styled.h5`
  ${boldFontStyle()}
  font-size: 12px;
  letter-spacing: 0.52px;
`;

export const BlackText = styled.h4`
  ${blackFontStyle()}
  font-size: 20px;
  letter-spacing: 0.52px;
`;

const GlobalStyle = createGlobalStyle`
  body {
    ${primaryFontStyle()};
    color: #fff;
    font-size: 16px;
    user-select: none;
    overflow:hidden;
    outline: none;
    margin: 0;
    padding: 0;
  }

  a:visited,
  a:link,
  a:active {
    text-decoration: none;
    color: #fff;
  }

  ul, li {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

  .prompt-box-8w {
    font-size: 17px;
    line-height: 26px;
    margin: 0;
    padding: 50px;
    font-weight: 400;
    z-index: 820;
    background: no-repeat top center url(${introBackgroundImage});
    background-color: rgba(37,53,93,0.3);
    color: ${theme.colors.white} !important;
    box-shadow: none !important;
    filter: none !important;
    outline: none !important;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    text-align: left;
    overflow-y: scroll;

    ${vp.medium(`
      background: no-repeat top center url(${introBackgroundImageIpad});
      background-size: cover;
    `)}
  }

  .prompt-box-8w p {
    display: none;
  }

  .custom-prompt-title {
    text-align: left;
    font-family: '72 Bold','sans-serif';
    font-size: 34px;
    line-height: 38px;
    padding: 100px 25px;

    ${vp.medium(`
      font-size: 62px;
      line-height: 68px;
      margin-bottom: 20px;
      padding: 100px 40px;
    `)}
  }

  .custom-prompt-description {
    text-align: left;
    display: block !important;
    font-family: '72 Regular','sans-serif';
    font-size: 15px;
    line-height: 28px;
    padding: 0 25px;

    ${vp.medium(`
      font-size: 21px;
      line-height: 35px;
      padding: 0 40px;
    `)}
  }

  .prompt-button-container-8w {
    position: fixed;
    bottom: 100px;
    min-width: 380px;
    left: 50%;
    transform: translateX(-50%);
  }

  .prompt-button-8w {
    border-radius: 20px !important;
    background-color: ${theme.colors.white} !important;
    color: ${theme.colors.blue} !important;
    font-size: 17px;
  }

  .prompt-button-8w:first-child {
    display: none !important;
  }

  #cameraPermissionsErrorAppleMessage,
  #cameraPermissionsErrorAndroidMessage {
    ${lightFontStyle()};
    font-size: 21px !important;
    line-height: 32px !important;
    color: white !important;
    text-align: left;
    position: fixed;
    top: 40%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }

`;

export default GlobalStyle;
