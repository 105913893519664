import React, { useMemo } from 'react';
import { useMobileOrientation } from 'react-device-detect';
import animationData from '../../lottie/rotate_animation.json';
import {
  OverlayContainer,
  LottieContainer,
  StyledLottie,
  Headline,
} from './styles';

function ScreenOrientationLock() {
  const { isLandscape } = useMobileOrientation();

  const overlay = useMemo(() => {
    return (
      <OverlayContainer>
        <LottieContainer>
          <StyledLottie
            autoplay={true}
            loop={true}
            animationData={animationData}
          />
          <Headline>Please rotate your device to portrait mode</Headline>
        </LottieContainer>
      </OverlayContainer>
    );
  }, []);

  return isLandscape ? overlay : null;
}

export default React.memo(ScreenOrientationLock);
