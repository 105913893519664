import ButtonArrow from '../components/buttons/ButtonArrow';
import {
  FooterContainer,
  FooterLink,
  TitleTxt,
  TitleTxtContainer,
  ViewContainer,
  ButtonContainer,
  ParagraphTxt,
} from './styles';
import SAPLogo from '../components/Logo';
import { useHistory } from 'react-router-dom';
import { ContentModel } from '../../src/utils/data';

function IntroPage() {
  const history = useHistory();
  return (
    <>
      <SAPLogo />
      <ViewContainer>
        <TitleTxtContainer>
          <TitleTxt>
            {ContentModel.landingPage.headline}
          </TitleTxt>
          <ParagraphTxt>
            {ContentModel.landingPage.intro_txt}
          </ParagraphTxt>
        </TitleTxtContainer>
        <ButtonContainer>
          <ButtonArrow
            width="280"
            isWhite
            text={ContentModel.landingPage.instructions}
            onClick={() => history.push('/graph_ar')}
          />
        </ButtonContainer>
        <FooterContainer>
          <FooterLink href="https://www.sap.com/corporate/en/legal/impressum.html" target="_blank">Imprint</FooterLink>
          <FooterLink href="https://www.sap.com/about/legal/privacy.html" target="_blank">Data Protection</FooterLink>
        </FooterContainer>
      </ViewContainer>
    </>
  );
}

export default IntroPage;
