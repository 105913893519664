import React from 'react';
import styled from 'styled-components'
import { RepositionIcon } from '../../../utils/svg';

const RepositionButton = (props) => {
    return (
        <RepositionBtnContainer>
            <RepositionBtn onClick={props.onClick}>
                <RepositionIcon/>
            </RepositionBtn>
        </RepositionBtnContainer>
    )
}

const RepositionBtn = styled.button`
    align-items: center;
    border: 8px solid white;
    border-radius: 50px;
    background-color: white;
    width: 40px;
    height: 40px;
    padding:0;
`;
const RepositionBtnContainer = styled.div`
    display: flex;
    justify-content:center;
    position: absolute; 
    transform: translate(-50%, 0);
    left: 10%;
    top: 90%;
`

export default RepositionButton;