import {
  ViewContainer,
  TitleTxtContainer,
  TitleTxt,
  ParagraphTxt,
  ButtonContainer,
} from './styles';
import ButtonArrow from '../buttons/ButtonArrow';
import { useEffect, useRef } from 'react';
import gsap from 'gsap'

type ExplorationGraphOverlayType = {
  onContinue: () => void;
};
const ExplorationGraphOverlay: React.FC<ExplorationGraphOverlayType> = ({
  onContinue,
}) => {
  const containerRef = useRef()


  useEffect(() => {
    gsap
      .fromTo(
        containerRef.current,
        {  opacity: 0, duration: 1, stagger:{amount:0.2}},
        {
          duration: 1,
          opacity: 1,
          ease: 'expo.inout',
        },
      )
  }, []);
  return (
    <ViewContainer>
      <TitleTxtContainer ref={containerRef}>
        <TitleTxt>There are issues within your Order-to-Cash process.</TitleTxt>
        <ParagraphTxt>Let the exploration begin.</ParagraphTxt>
      </TitleTxtContainer>
      <ButtonContainer>
        <ButtonArrow
          width="280"
          isWhite
          text="Start Optimizing"
          onClick={() => onContinue()}
        />
      </ButtonContainer>
    </ViewContainer>
  );
};

export default ExplorationGraphOverlay;
