import React, { useRef, useMemo, useEffect } from 'react'
import { useFrame } from '@react-three/fiber'
import { STATUS, useGraphStore } from '../../../services/graphService';
import { Mesh, Vector3, BufferAttribute, InstancedBufferAttribute, InstancedBufferGeometry,DoubleSide, Color } from 'three'

// eslint-disable-next-line import/no-webpack-loader-syntax
import vertexShader from '!!raw-loader!../../../shaders/lineInstanceVertex.glsl';
// eslint-disable-next-line import/no-webpack-loader-syntax
import fragmentShader from '!!raw-loader!../../../shaders/lineInstanceFragment.glsl';
import irradiance from './irradiance';
import { getObjectPosition } from '../../../utils/data';
import { prototype } from 'events';
import { useXrStore } from '../../../services/xrService';

const randomizeBaseNum = (e) => Math.random() ** e * 2 - 1;

export default function LinesInstance(props) {
  const segments = 32;
  const loggingEnabled = false;
  const ref = useRef<Mesh>();
  const {startSphere, endSphere} = props;
  const { getSphereById } = useGraphStore();
  const { scaleGraphParam } = useXrStore();
  const startSphereStore = getSphereById(startSphere);
  const endSphereStore = getSphereById(endSphere);


  const shaderProps = useMemo(
    () => ({
      uniforms: {

        u_PrimaryColor: {
          type: 'c',
        value: new Color("#252B98")
      },
      u_SecondaryColor: {
        type: 'c',
        value: new Color("#3856D7")
      },
      u_HealtyColor: {
        type: 'c',
        value: new Color("#45B3CF")
      },
      u_WoundColor: {
        type: 'c',
        value: new Color("#B92D45")
      },
        u_startPoint: {
          type: 'v3',
          value: new Vector3(-2.5, 0, 0)
        },
        u_controlPoint1: {
          type: 'v3',
          value: new Vector3(-1, 1, 0)
        },
        u_controlPoint2: {
          type: 'v3',
          value: new Vector3(1, -1, 0)
        },
        u_endPoint: {
          type: 'v3',
          value: new Vector3(2.5, 0, 0)
        },
        u_fTime: {
          value: 0
        },
        u_fHealthState: {
          value: 0
        },
        u_fMovementIntensity: {
          value: 1
        },
        u_vIrradiance: {
          value: irradiance,
        },
        u_StartState: {
          type: 'i',
          value: 1
        },
        u_EndState: {
          type: 'i',
          value: 1
        },
        u_WoundedRadius: {
          type: 'f',
          value: 0.3
        },
        u_Thickness:  {
          type: 'f',
          value: 1
        },
        u_Brightness:  {
          type: 'f',
          value: 1
        },
      },
      fragmentShader,
      vertexShader
    }),
    []
  );
const printLog = (s) => {
  if(loggingEnabled)
  console.log(s);
}

useEffect(() => {
    const positions = new Float32Array((segments + 1) * 4);
    const indices = [];

    for (let i = 0; i <= segments; i += 1) {
      const ptr = i * 4;
      const y = i / segments;

      // make lines thin at the start and end point (cap off ends)
      const x = i === 0 || i === segments ? 0 : 1;

      // x coord from -1 to 1 (left side of graph or right side)
      // y is 0 to 1 (left to right)
      positions[ptr] = -x;
      positions[ptr + 1] = y;
      positions[ptr + 2] = x;
      positions[ptr + 3] = y;

      if (i < segments) {
        indices.push(...[0, 1, 2, 2, 1, 3].map((n) => i * 2 + n));
      }
    }
    printLog("Setting number of lines: " + props.numberOfLines);
    const p1Offsets = new Float32Array(props.numberOfLines * 3);
    const p2Offsets = new Float32Array(props.numberOfLines * 3);
    const thicknesses = new Float32Array(props.numberOfLines);

    for (let i = 0; i < p1Offsets.length; i++) {
      p1Offsets[i] = randomizeBaseNum(2) * 0.5;
      p2Offsets[i] = randomizeBaseNum(2) * 0.5;
    }

    // amount line instances spread out
    const spreadRandomized = 0.9;

    for (let i = 0; i < thicknesses.length; i++) {
      const ptr = i * 3;

      // randomize thickness and spread
      // const thicknessVariance = 2.5;
      // const thickness = Math.random() ** thicknessVariance * 0.9 + 0.1;
     //const thickness = props.lineThickness[i % props.lineThickness.length] + Math.random(); //With slight variation
     const thickness = props.lineThickness;

      const thicknessSpread = 0.2 + 0.8 * ((1 - thickness) ** 4 * 1.3);

      p1Offsets[ptr] = randomizeBaseNum(spreadRandomized) * thicknessSpread;
      p1Offsets[ptr + 1] = randomizeBaseNum(spreadRandomized) * thicknessSpread;
      p1Offsets[ptr + 2] = randomizeBaseNum(spreadRandomized) * thicknessSpread;

      p2Offsets[ptr] = randomizeBaseNum(spreadRandomized) * thicknessSpread;
      p2Offsets[ptr + 1] = randomizeBaseNum(spreadRandomized) * thicknessSpread;
      p2Offsets[ptr + 2] = randomizeBaseNum(spreadRandomized) * thicknessSpread;
      thicknesses[i] = 1;
    }

    const geometry: InstancedBufferGeometry = new InstancedBufferGeometry();
    geometry.setAttribute('a_vPos', new BufferAttribute(positions, 2));
    geometry.setIndex(indices);
    geometry.setAttribute('a_controlPoint1Offset', new InstancedBufferAttribute(p1Offsets, 3));
    geometry.setAttribute('a_controlPoint2Offset', new InstancedBufferAttribute(p2Offsets, 3));
    geometry.setAttribute('a_fThickness', new InstancedBufferAttribute(thicknesses, 1));

    ref.current.geometry = geometry;

    // overwrite default raycast method of mesh because it doesn't work with Instanced Buffer Geometry
    ref.current.raycast = (...args) => {
      return false;
    }
  },[/*props.lineThickness, props.numberOfLines*/]);

  useEffect(() => {
    ref.current.material.uniforms.u_startPoint.value.set(props.startPoint[0],props.startPoint[1],props.startPoint[2]);
  },[props.startPoint]);


  function vectorDistance( v1, v2 )
  {
      var dx = v1[0] - v2[0];
      var dy = v1[1] - v2[1];
      var dz = v1[2] - v2[2];
      return Math.sqrt( dx * dx + dy * dy + dz * dz );
  }
  const GetStateValue = (status) => {
    switch (status) {
      case STATUS.wounded:
        return 1;
      default:
        return 0;
    }
  };

  useEffect(() => {
    //console.log("Start Sphere Status: " + startSphereStore?.status + ", End Sphere Status: " + endSphereStore?.status);
    let isStartSphereStart = vectorDistance( getObjectPosition(startSphereStore), props.startPoint) < vectorDistance(getObjectPosition(startSphereStore), props.endPoint);
    if(isStartSphereStart)
    {
      ref.current.material.uniforms.u_StartState.value = GetStateValue(startSphereStore?.status);
      ref.current.material.uniforms.u_EndState.value = GetStateValue(endSphereStore?.status);
    }else {
      ref.current.material.uniforms.u_StartState.value = GetStateValue(endSphereStore?.status);
      ref.current.material.uniforms.u_EndState.value = GetStateValue(startSphereStore?.status);
    }
  }, [startSphereStore?.status, endSphereStore?.status]);

  useEffect(() => {
    printLog("Setting control point 1..." + props.controlPoint1[0]);
    ref.current.material.uniforms.u_controlPoint1.value.set(props.controlPoint1[0],props.controlPoint1[1],props.controlPoint1[2]);
  },[props.controlPoint1]);

  useEffect(() => {
    printLog("Setting control point 1..." + props.controlPoint2[0])
    ref.current.material.uniforms.u_controlPoint2.value.set(props.controlPoint2[0],props.controlPoint2[1],props.controlPoint2[2]);
  },[props.controlPoint2]);

  useEffect(() => {
    printLog("Setting control point 1..." + props.endPoint[0])
    ref.current.material.uniforms.u_endPoint.value.set(props.endPoint[0],props.endPoint[1],props.endPoint[2]);
  },[props.endPoint]);

  useEffect(() => {
    printLog("Setting healthstate...");
    ref.current.material.uniforms.u_fHealthState.value = props.healthState ? 1.0 : 0.0;
  },[props.healthState]);

  useEffect(() => {
    printLog("Setting movementintensity...");
    ref.current.material.uniforms.u_fMovementIntensity.value = props.movementIntensity;
  },[props.movementIntensity]);

  const lerp = (start, end, amt) => {
    return (1-amt)*start+amt*end;
  };

  useFrame((state, delta) => {
    const elapsedTimeSeconds = state.clock.getElapsedTime();

    // animates position of control points up and down (and with it the whole graph)
    const heightInfluence = 0.1;
    ref.current.material.side = DoubleSide;
    // animate the control points based on current time
    ref.current.material.uniforms.u_controlPoint1.value.set(props.controlPoint1[0], props.controlPoint1[1] + Math.cos(elapsedTimeSeconds * 0.65) * heightInfluence * props.movementIntensity, props.controlPoint1[2]);
    ref.current.material.uniforms.u_controlPoint2.value.set(props.controlPoint2[0], props.controlPoint2[1] + Math.cos(-elapsedTimeSeconds * 0.4 + 10) * heightInfluence * props.movementIntensity,props.controlPoint2[2] );

    const targetThickness = scaleGraphParam * props.lineThickness;
    const dist = Math.abs(ref.current.material.uniforms.u_Thickness.value - targetThickness);
    const brightness = (dist > 0.05) ? 1.5 : 1;

    ref.current.material.uniforms.u_Thickness.value = lerp(ref.current.material.uniforms.u_Thickness.value, targetThickness, delta * 8);
    ref.current.material.uniforms.u_Brightness.value = lerp(ref.current.material.uniforms.u_Brightness.value, brightness, delta * 8);

    // todo: check if % can have a smooth flow
    ref.current.material.uniforms.u_fTime.value = elapsedTimeSeconds % 60;
  });

  return ( <group>
      <mesh frustumCulled={false} ref={ref}>
        <rawShaderMaterial attach="material" {...shaderProps} />
      </mesh>
    </group> )}
