import create from 'zustand';
import * as THREE from 'three';
import { Vector3 } from '@react-three/fiber';

const SCALE_PARAM_STEPS = 0.5;
// Graph scaling params
const SCALE_GRAPH_PARAM_MAX = 4.5;
const SCALE_GRAPH_PARAM_MIN = 1.0;
// Anamorphic scaling params
const SCALE_ANAMORPHIC_PARAM_MAX = 8.0;
const SCALE_ANAMORPHIC_PARAM_MIN = 4.0;

type XrState = {
  scene3: any,
  renderer: any,
  isSceneInitialised: boolean,
  isLookingAtCamera: boolean,
  firstCameraPos: null,
  screenTouchedFirstTimeAR: boolean,
  modelPositionAR: number[],
  modelRotationAR: number[],
  groundRef: any,
  wallRef: any,
  povRef: any,
  scaleGraphParam: number,
  scaleAnamorphicParam: number,
  side: boolean,

  whichSide: (camStartPos: Vector3, scenePos: Vector3, camPos: Vector3) => void,
  setScene3: (scene: any) => void,
  setRenderer: (renderer: any) => void,
  setIsLookingAtCamera: (isLooking: boolean) => void,
  setFirstCameraPos: (pos: Vector3) => void,
  setScreenTouchedFirstTimeAR: (bool: boolean) => void,
  placeModelAR: (pos: Vector3) => void,
  applyRotationAR: () => void,
  setGroundRef: (ref: any) => void,
  setWallRef: (ref: any) => void,
  setPovRef: (ref: any) => void,
  setScaleGraphParam: (newScaleBool: boolean) => void,
  setScaleAnamorphicParam: (newScaleBool: boolean) => void,
  scaleGraphUp: () => void,
  scaleGraphDown: () => void,
  scaleAnamorphicUp: () => void,
  scaleAnamorphicDown: () => void,

}

export const useXrStore = create<XrState>((set, get) => ({
  scene3: {}, // will be an object with scene.scene and scene.camera
  renderer: null,
  isSceneInitialised: false,
  isLookingAtCamera: false,
  firstCameraPos: THREE.Vector3,
  screenTouchedFirstTimeAR: false,
  modelPositionAR: [0, 0, 0],
  modelRotationAR: [0, 0, 0],
  groundRef: null,
  wallRef: null,
  povRef: null,
  scaleGraphParam: 2.0, // initial scale of graph
  scaleAnamorphicParam: 6.0, // initial scale of anamorphic model
  side: false,


  // Indicates side (left/right) where the user is standing in regard to
  // vector formed by model (model -> target)
  whichSide: (camStartPos, scenePos, camPos) => {
    //remove Y component, just horizontal axes
    const _a = new THREE.Vector2(camStartPos.x, camStartPos.z);
    const _b = new THREE.Vector2(scenePos.x, scenePos.z);
    const _c = new THREE.Vector2(camPos.x, camPos.z);

    // true == right; false == left
    set({ side: ((_b.x - _a.x) * (_c.y - _a.y) - (_b.y - _a.y) * (_c.x - _a.y)) > 0 });
  },

  setScene3: (scene) => {
    set({ scene3: { scene } })
    set({ isSceneInitialised: true });
  },


  setRenderer: (renderer) => {
    set({ renderer: renderer})
  },

  setIsLookingAtCamera: (isLooking) => set({ isLookingAtCamera: isLooking }),

  setFirstCameraPos: (pos) => set({ firstCameraPos: pos }),

  setScreenTouchedFirstTimeAR: (bool) => set({ screenTouchedFirstTimeAR: bool }),

  placeModelAR: (pos) => set({ modelPositionAR: pos }),

  applyRotationAR: () => {
    const cameraPosition = get().scene3.scene.camera.position;
    const arModelPosition = get().modelPositionAR;

    const directionCamera = new THREE.Vector3(
        cameraPosition.x - arModelPosition[0],
        cameraPosition.y - arModelPosition[1],
        cameraPosition.z - arModelPosition[2]);

    directionCamera.normalize();

    set({ modelRotationAR: [
        0.0,
        Math.atan2(directionCamera.x, directionCamera.z) + Math.PI,
        0.0]
    })
  },

  setGroundRef: (ref) => set({ groundRef: ref }),

  setWallRef: (ref) => set({ wallRef: ref }),

  setPovRef: (ref) => set({ povRef: ref }),

  scaleGraphUp: () => set( state => ({ scaleGraphParam: Math.min(state.scaleGraphParam + SCALE_PARAM_STEPS, SCALE_GRAPH_PARAM_MAX) })),

  scaleGraphDown: () => set( state => ({ scaleGraphParam: Math.max(state.scaleGraphParam - SCALE_PARAM_STEPS, SCALE_GRAPH_PARAM_MIN) })),

  scaleAnamorphicUp: () => set( state => ({ scaleAnamorphicParam: Math.min(state.scaleAnamorphicParam + SCALE_PARAM_STEPS, SCALE_ANAMORPHIC_PARAM_MAX) })),

  scaleAnamorphicDown: () => set( state => ({ scaleAnamorphicParam: Math.max(state.scaleAnamorphicParam - SCALE_PARAM_STEPS, SCALE_ANAMORPHIC_PARAM_MIN) })),

  setScaleGraphParam: (newScaleBool) => (
    newScaleBool ?
    get().scaleGraphParam <= SCALE_GRAPH_PARAM_MAX && get().scaleGraphUp() :
    get().scaleGraphParam >= SCALE_GRAPH_PARAM_MIN && get().scaleGraphDown()
  ),

  setScaleAnamorphicParam: (newScaleBool) => (
    newScaleBool ?
    get().scaleAnamorphicParam <= SCALE_ANAMORPHIC_PARAM_MAX && get().scaleAnamorphicUp() :
    get().scaleAnamorphicParam >= SCALE_ANAMORPHIC_PARAM_MIN && get().scaleAnamorphicDown()
  ),
}));
