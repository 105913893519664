import { DescriptionText,
  ElementsContainer,
  HeadLineText,
  ButtonContainer
} from './styles';
import PopUpContainer from '../PopUpContainer';
import { ContentModel } from '../../utils/data';
import  ButtonArrow  from '../buttons/ButtonArrow'
import { useHistory } from 'react-router-dom';


const FinalPopUp: React.FC = () => {
  const contentPopup = ContentModel.finalPopup;
	const history = useHistory();

  return (
    <PopUpContainer>
      <ElementsContainer>
        <HeadLineText>
          {contentPopup.headline}
        </HeadLineText>
        <DescriptionText>
        	{contentPopup.popup_txt}
        </DescriptionText>
			<ButtonContainer>
				<ButtonArrow isMagnetic={false}
                width="210"
                isWhite
                text={contentPopup.instructions}
                onClick={() => history.push('/takeaway')}/>
			</ButtonContainer>
      </ElementsContainer>
    </PopUpContainer>
  );
};

export default FinalPopUp;
