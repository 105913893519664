import { Color, BackSide } from 'three';
import { PLANE_HEIGHT, PLANE_SIZE } from '../../constants';

// ground plane for raycast intersection.
export const GroundPlane = ( props ) => {
    return (
        <>
            {/* GROUND */}
            <mesh
                ref={props.groundRef}
                name={'groundPlane'}
                position={[0, PLANE_HEIGHT, 0]}
                rotation={[-Math.PI * 0.5, 0.0, 0.0]}
                visible={false}
                >
                <planeBufferGeometry args={[PLANE_SIZE, PLANE_SIZE]} />
                <meshBasicMaterial
                    color={new Color(0xff00ff)}
                    wireframe={true}
                />
            </mesh>
            {/* WALLS */}
            <mesh
                ref={props.wallRef}
                name={'wallPlane'}
                position={[0, PLANE_SIZE/4, 0]}
                visible={false}
                >
                <boxBufferGeometry args={[PLANE_SIZE, PLANE_SIZE/2, PLANE_SIZE]} />
                <meshBasicMaterial
                    color={new Color(0x00ff00)}
                    wireframe={true}
                    side={BackSide}
                />
            </mesh>
        </>
    )

}

