import gsap from 'gsap';

import { useEffect, useState } from 'react';
import { UIGraphContainer, LinkExitExperience } from './styles';
import PopUpGraphContainer from '../PopUpGraphContainer';
import { GRAPH_SCREENS, useUIStore, BASE_TIME } from '../../services/uiService';
import { useXrStore } from '../../services/xrService';
import TimeIndicator from '../TimeIndicator';
import { useGraphStore, STATUS } from '../../services/graphService';
import { getDateFromHours } from '../../utils/time';
import { ContentModel } from '../../utils/data';
import CircularBar from '../CircularBar/CircularBar';
import  ScreenOrientationLock  from '../ScreenOrientationLock/'
import InitialPopUp from '../InitialPopUp';
import ExplorationGraphOverlay from '../ExplorationGraphOverlay';
import CounterWoundPoints from '../CounterWoundPoints';
import ButtonArrow from '../buttons/ButtonArrow';
import { ButtonContainer } from '../ExplorationGraphOverlay/styles';
import FocusArea from '../FocusArea';
import ZoomButton from '../buttons/ZoomButton';
import { useGameStore } from '../../services/gameService';
import FinalPopUp from '../FinalPopUp';
import useSleep from '../../hooks/useSleep';
import RepositionButton from '../buttons/RepositionButton';

// eslint-disable-next-line max-lines-per-function
const UIGraphModel: React.FC = () => {
  const {
    setStatusSphere,
    getSelectedSphere,
    setSelectedSphere,
    getSolvedSpheres,
    goToNextStep,
    loadStepInToGraph,
    setConnectionThicknessById,
    setLastSphereSelected,
    isHealthy,
    isSolved,
    isHealthyInActiveStep,
  } = useGraphStore();

  const {
    showLegendGraph,
    percentage,
    percentageInverse,
    showImprovementCircularBar,
    screenGraphGame,
    showInitialPopUp,
    showFinalPopUp,
    showCounter,
    showFocusArea,
    setScreenGraphGame,
    setShowLegendGraph,
    setShowFocusArea,
    setTimeDays,
    setShowImprovementCircularBar,
    setShowInitialPopUp,
    setShowFinalPopUp,
    setShowCounter,
    showExitLink,
    setShowExitLink,
    setTransitionScreenShowedUp
  } = useUIStore();
  const {
    setScreenTouchedFirstTimeAR,
    setScaleGraphParam,
    screenTouchedFirstTimeAR,
  } = useXrStore();
  const { currentGame } = useGameStore();

  const [solvedTime, setSolvedTime] = useState([0, 0]);
  const [totalSolvedTime, setTotalSolvedTime] = useState(0);
  const [timeIndicatorData, setTimeIndicatorData] = useState([7, 0]);
  const [isFirstScreenOnScreen, setSsFirstScreenOnScreen] = useState(false);
  const sleep = useSleep();

  const selectedSphere = getSelectedSphere();
  const contentSphereSelected = ContentModel.getContentSphereById(
    selectedSphere?.contentid,
  );
  const selectedSphereID = selectedSphere?.pointid;
  const isHealthySphere = isHealthy(selectedSphere);
  const isSolvedSphere = isSolved(selectedSphere);
  const allSpheresSolved = getSolvedSpheres() === 4;

  const selectedSphereInfo = contentSphereSelected?.label;
  const closeInformationBox = () => {
    setShowLegendGraph(false);
    setLastSphereSelected(selectedSphereID);
    setSelectedSphere(selectedSphereID, false);
  };

  const changeTheThicknessOfConnection = (newThicknesses) => {
    if (newThicknesses) {
      newThicknesses.map((newThickness) =>
        setConnectionThicknessById(newThickness.id, newThickness.newThickness),
      );
    }
  };

  const clickOnContainer = () => {
    if (screenTouchedFirstTimeAR && isSolvedSphere)  {
      closeInformationBox();
      gsap.delayedCall(0.5, () => setShowFocusArea(true));
    }
  };

  const handleTimeDifference = () => {
    const timeDiff =
      BASE_TIME - (contentSphereSelected.time_diff_hrs + totalSolvedTime);
    setTotalSolvedTime(
      (totalSolvedValue) =>
        totalSolvedValue + contentSphereSelected.time_diff_hrs,
    );
    setSolvedTime(getDateFromHours(contentSphereSelected.time_diff_hrs));
    const currentImprovedTime = getDateFromHours(timeDiff);
    setTimeDays(contentSphereSelected.time_diff_hrs + totalSolvedTime);
    setTimeIndicatorData(currentImprovedTime);
  };

  const showGuidanceInformationBox = (delay:number):void => {
    setSsFirstScreenOnScreen(true);

    gsap.delayedCall(delay, () => {
      setShowInitialPopUp(true);
    });

    gsap.delayedCall(delay + 4, () => {
      setShowInitialPopUp(false);
    });

  } 

  const onSphereSolved = () => {
    setStatusSphere(selectedSphereID, STATUS.woundedSolved);

    gsap.delayedCall(0.5, () => {
      setShowFocusArea(false);
      const { lineAnimations } = goToNextStep();
      loadStepInToGraph();
      closeInformationBox();
      setLastSphereSelected(selectedSphereID);
      setSelectedSphere(selectedSphereID, false);
      changeTheThicknessOfConnection(lineAnimations);

      gsap.delayedCall(2, () => {
        setShowImprovementCircularBar(true);
      });

      gsap.delayedCall(5, () => {
        gsap.delayedCall(0.5, () => handleTimeDifference())
        setShowImprovementCircularBar(false);
        setShowExitLink(true);
        gsap.delayedCall(0.5, () => setShowFocusArea(true));
      });

      showGuidanceInformationBox(6);
    });
  };

  const onSphereHealthySolved = () => {
    setShowLegendGraph(false);
    gsap.delayedCall(0.5, () => {
      closeInformationBox();
      gsap.delayedCall(0.5, () => setShowFocusArea(true));
      setStatusSphere(selectedSphereID, STATUS.healthySolved);
      goToNextStep();
      loadStepInToGraph();
    });

    showGuidanceInformationBox(1);
  };

  const onSphereAction = () => isHealthySphere ?
    onSphereHealthySolved() : onSphereSolved();

  const returnToReposition = () => {
    setScreenTouchedFirstTimeAR(false)
    setTransitionScreenShowedUp(true) // so transition screen doesnt popup again
    setScreenGraphGame(GRAPH_SCREENS.pre)
  }

  useEffect(() => {
    if (allSpheresSolved)
      gsap.delayedCall(6, () => {
        setShowFinalPopUp(true);
        setShowFocusArea(false);
      });
  }, [allSpheresSolved]);

  // eslint-disable-next-line complexity
  const renderSearchPointsScreen = () => (
    <>
      <TimeIndicator
        days={timeIndicatorData[0]}
        hours={timeIndicatorData[1]}
        percentage={percentageInverse}
      />
      {showInitialPopUp && 
        !showFinalPopUp && 
        <InitialPopUp 
          game={currentGame}
          showInstructions={isFirstScreenOnScreen}
          isThereHealthy={isHealthyInActiveStep()}
        />
      }
      {showCounter && (
        <CounterWoundPoints
          totalSpheres={4}
          solvedSpheres={getSolvedSpheres()}
        />
      )}
      {showLegendGraph && !showImprovementCircularBar && (
        <PopUpGraphContainer
          selectedSphere={selectedSphere}
          onEnd={onSphereAction}
        />
      )}
      {showImprovementCircularBar && (
        <CircularBar
          text="Improved cycle time by"
          value={percentage}
          days={solvedTime[0]}
          hours={solvedTime[1]}
        />
      )}
      {!showFinalPopUp &&
        showExitLink &&
        !showImprovementCircularBar &&
        !showLegendGraph &&
        !showInitialPopUp && (
        <>
          <RepositionButton onClick={() => returnToReposition()}/>
          <LinkExitExperience to="/takeaway">
            Exit the experience
          </LinkExitExperience>
        </>
        )}
      {showFinalPopUp && <FinalPopUp />}
    </>
  );

  const renderScreens = () => {
    switch (screenGraphGame) {
      case GRAPH_SCREENS.pre:
        return (
          <>
            <ButtonContainer>
              <ButtonArrow
                isMagnetic={false}
                width="180"
                isWhite
                text="Place Object"
                onClick={() => setScreenTouchedFirstTimeAR(true)}
              />
            </ButtonContainer>
            <ZoomButton
              zoomIn={() => setScaleGraphParam(true)}
              zoomOut={() => setScaleGraphParam(false)}
            />
          </>
        );
      case GRAPH_SCREENS.transition:
        return (
          <ExplorationGraphOverlay
            onContinue={() => setScreenGraphGame(GRAPH_SCREENS.search)}
          />
        );
      case GRAPH_SCREENS.search:
        return renderSearchPointsScreen();
      default:
        return null;
    }
  };

  useEffect(() => {
    const handleHideInitialPopUp = () => {
      selectedSphere && setShowInitialPopUp(false);
    };
    handleHideInitialPopUp();
  }, [selectedSphere, setShowInitialPopUp]);

  const checkSolvedStatus = (sphere) => {
    return (
      sphere?.status === STATUS.healthySolved ||
      sphere?.status === STATUS.woundedSolved
    );
  };

  useEffect(() => {
    const handleShowCounter = () => {
      if (checkSolvedStatus(selectedSphere)) setShowCounter(true);
    };
    handleShowCounter();
  }, [selectedSphere, setShowCounter]);

  return (
    <UIGraphContainer onClick={() => clickOnContainer()}>
      <ScreenOrientationLock />
      {renderScreens()}
      {showFocusArea && (
        <FocusArea
          selectedSphere={getSelectedSphere()}
          text={selectedSphereInfo}
        />
      )}
    </UIGraphContainer>
  );
};

export default UIGraphModel;
